<template>
  <div class="mobileScreen-main-container">
    <MobileHeader />
    <div
      class="mobileScreen-main-innerContainer d-flex align-items-center justify-content-center flex-column"
    >
      <div class="mobilescreen-completed">
        Completed Successfully
      </div>
      <div
        class="mt-4 text-center mobileScreen-TD-otherInfo-subhead"
        style="width: 93%;"
      >
        You have successfully completed this test.
      </div>
      <div class="mt-4">
        <img src="../../assets/mobileScreenIcons/completed-circle.svg" alt="" />
      </div>
      <div class="mt-3 mcompleted-subtext ">
        You can check your assessment progress in your candidates portal ID
      </div>
      <div
        @click="openWebsite"
        class="mt-5 mobileScreen-CT-testAgain-text pointer"
      >
        Okay, got it
      </div>
    </div>
  </div>
</template>

<script>
import MobileHeader from "./MobileHeader.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    MobileHeader,
  },
  props:{
context:Object,
  },
  computed: {
    ...mapGetters("sectionVUEX", {
      testDetails: "getTestDetails",
    }),
    ...mapGetters("testVUEX", {
      viewRemarks: "getviewRemarks",
    }),
  },
  methods: {
    openWebsite() {
      try {
        if (this.testDetails.config.allowCandidateRemarks) {
          window.removeEventListener("beforeunload", this.handleBeforeUnload);
          this.$store.commit('testVUEX/setViewRemarks', true);          // this.$router.push(
          // this.$router.push(
          //   {
          //     path: "/remarks",
          //   },
          //   // () => {
          //   //   this.$router.go(0);
          //   // }
          // );
        } else {
          localStorage.clear();
          this.$store.commit('testVUEX/setViewRemarks', false);          // this.$router.push(

          location.replace("https://www.hire3x.com/");
        }
      } catch (error) {
        location.replace("https://www.hire3x.com/");
        console.log(error);
      }
    },
    testFinsihed() {
      this.$router.push({ path: "/mlogin" });
    },
  },
};
</script>
