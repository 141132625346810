<template>
    <div>
        <div class="screenSizeLap">
      <div class="login-main-container">
        <div class="h-100 d-flex align-items-center justify-content-center">
          <!-- do bind the style vth height if the typing test is over -->
          <div class="login-main-sub-container position-relative">
            <b-row class="m-0 w-100">
              <b-col class="p-0">
                <div
                  class="w-100 d-flex justify-content-center align-items-baseline"
                >
                  <img src="../assets/newimgs/GREYLOGO.svg" alt="" />
                </div>
              </b-col>
            </b-row>
            <b-row class=" m-0 w-100" style="height: 95%;">
              <b-col class="p-0">
                <div
                  class=" d-flex align-items-center flex-column mt-3 text-center completeddetails-container"
                >
                  <div
                    class="h-100 w-75 d-flex justify-content-center flex-column"
                  >
                    <h1 class="login-rightcont-headtxt pt-3">
                      Completed Successfully
                    </h1>

                    <div class="mt-3 h-100">
                      <div
                        class="d-flex align-items-center mt-5 flex-column"
                      >
                        <div
                        style="width: fit-content;"
                          class="mt-3 completed-card-container px-5 py-4 position-relative"
                        >
                          <div
                            class="cmpltedsucc-icon"
                            style="left: 42%;top:-40px"
                          >
                            <img
                              src="../assets/newui-img/completed.svg"
                              alt=""
                            />
                          </div>
                          <div
                            class="d-flex align-items-center flex-column justify-content-center mt-5"
                          >
                            <div class="comp-maintxt">
                              Congratulations! You have successfully completed
                              your <br/> assessment test.
                            </div>
                            <div class="mt-4 comp-subtxt">
                              We appreciate your effort and dedication. To help
                              us improve, we <br/> would love to hear your feedback on
                              your assessment <br/> experience.
                            </div>
                          </div>
                          <div
                            class="mt-5  d-flex align-items-center justify-content-center bluebtns"
                          >  <b-button v-if="context.testDetails.config.allowCandidateRemarks" variant="primary" @click="closeTest"
                              >Okay</b-button
                            >
                            <b-button v-else variant="primary" @click="context.openWebsite"
                              >Go To Hire3x</b-button
                            >
                          
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
    </div>
    <div class="screenSizeMobile">
      <Mcompleted :context="context" />
    </div>
    </div>
</template>
<script>
import Mcompleted from "./mobileView/Mcompleted.vue";
import { mapGetters } from "vuex";

export default {
    name:"CompletedTest",
  components: {
    Mcompleted,
  },
  props:{
    context:Object,
  },
  computed: {
    // ...mapGetters("sectionVUEX", {
    //   testDetails: "getTestDetails",
    // }),
    ...mapGetters("testVUEX", {
      viewRemarks: "getviewRemarks",
    }),
  },
methods:{
  closeTest(){
    this.$store.commit('testVUEX/setViewRemarks', true);       
    }
}

  // mounted() {
  //   (function() {
  //     if (window.localStorage) {
  //       if (!localStorage.getItem("firstLoad")) {
  //         localStorage["firstLoad"] = true;
  //         window.location.reload();
  //       } else localStorage.removeItem("firstLoad");
  //     }
  //   })();
  // },
};
</script>

<style scoped>
@media only screen and (max-width: 821px) {
  .screenSizeLap {
    display: none;
  }
}

@media only screen and (min-width: 821px) {
  .screenSizeMobile {
    display: none;
  }
}

.completeddetails-container {
  height: calc(100vh - 123px);
  overflow: auto;
  border-radius: 14px;
  background: url("../assets/newui-img/checkkk.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 30px;
  width: 100%;
}
.comp-maintxt {
  font-family: "Lato", sans-serif;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  color: #3a3c40;
}
.comp-subtxt {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 13px;
  text-align: center;
  color: #3a3c40;
}
</style>